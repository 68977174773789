.flatpickr-calendar {
    --calendar-gutter-x: 10px;
    padding: 0 var(--calendar-gutter-x) 15px;
    font-size: 12px;
    line-height: 14/12*1em;
    width: 280px;
    box-shadow: none;
    border: 1px solid var(--border-grey-color);
    border-radius: var(--radius-base);
    background: var(--background-secondary-color);
    color: var(--text-primary-color);

    .calendar-inline & {
        width: auto;
        padding: 0;
        margin: 0 -11px;
        border: none;
        font-size: 1rem;
        line-height: 18/15*1em;
        background: transparent;
    }

    &::before,
    &::after {
        position: absolute;
        left: 16px;
        content: "";
        width: 0;
        height: 0;
        pointer-events: none;

        .calendar-inline & {
            display: none;
        }
    }

    &::before {
        margin: 0;
        border: 8px solid transparent;
    }

    &::after {
        z-index: 1;
        margin: 0 2px;
        border: 6px solid transparent;
    }

    &.arrowTop {
        margin-top: 5px;

        .calendar-inline & {
            margin-top: 0;
        }

        &::before {
            border-top-width: 0;
            border-bottom-color: var(--border-grey-color);
        }

        &::after {
            border-top-width: 0;
            border-bottom-color: var(--background-secondary-color);
        }
    }

    &.arrowBottom {
        margin-top: -38px;

        .calendar-inline & {
            margin-top: 0;
        }

        &::before {
            border-bottom-width: 0;
            border-top-color: var(--border-grey-color);
        }

        &::after {
            border-bottom-width: 0;
            border-top-color: var(--background-secondary-color);
        }
    }
}

.flatpickr-rContainer {
    width: 100%;
}

.flatpickr-months {
    position: relative;
    padding: 20px 10px 18px;
    margin: 0 calc(var(--calendar-gutter-x) * -1) 1rem;
    border-bottom: 1px solid var(--border-grey-color);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    background-color: var(--background-primary-color);

    .calendar-inline & {
        padding: 0;
        margin: 0 0 2.2667rem;
        border: none;
        border-radius: 0;
        background-color: transparent;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14%;
        transform: translateY(-50%);
        color: var(--text-primary-color);
        transition: var(--t-base);

        .calendar-inline & {
            margin: 0!important;
        }

        svg {
            vertical-align: top;
            height: 12px;
            fill: currentColor;

            .calendar-inline & {
                height: auto;
            }
        }

        &:hover {
            color: var(--primary-color);

            svg {
                fill: currentColor;
            }
        }
    }

    .flatpickr-prev-month {
        margin-left: var(--calendar-gutter-x);
    }

    .flatpickr-next-month {
        margin-right: var(--calendar-gutter-x);
    }

    .flatpickr-month {
        position: relative;
        height: auto;
        font-size: 15px;
        line-height: 18/15*1em;
        color: var(--text-primary-color);
        overflow: initial;

        .calendar-inline & {
            font-size: 1.2rem;
            line-height: 21/18*1em;
        }
    }
}

.flatpickr-current-month {
    position: static;
    left: auto;
    width: auto;
    height: auto;
    padding: 0;
    font-weight: 500;
    font-size: inherit;
    line-height: inherit;
    color: inherit;

    .calendar-inline & {
        pointer-events: none;
    }

    .numInputWrapper {
        width: auto;
    }

    span.cur-month {
        font-weight: 500;
        color: inherit;
        display: inline-block;
        margin-left: 0;

        &:hover {
            background-color: transparent;
        }
    }

    input.cur-year {
        width: 4ch;
        padding: 0;
        margin-left: 1ch;
    }
}

.numInputWrapper {
    color: var(--grey-color-6);
    font-weight: normal;

    .calendar-inline & {
        margin-left: 1ch;
        font-weight: 500;
        color: var(--text-primary-color);
    }

    &:hover {
        background-color: transparent;
    }

    .arrowUp,
    .arrowDown {
        right: -2ch;
        border: none;
    }

    .arrowUp::after {
        border-bottom-color: currentColor!important;
    }

    .arrowDown::after {
        border-top-color: currentColor!important;
    }
}

.flatpickr-weekdays {
    height: auto;
    margin-bottom: 12px;
    overflow: initial;

    .calendar-inline & {
        margin-bottom: 18px;
    }
}

span.flatpickr-weekday {
    font-weight: normal;
    font-size: inherit;
    line-height: inherit;
    text-transform: uppercase;
    color: var(--text-secondary-color);

    .calendar-inline & {
        font-weight: 500;
        font-size: 14px;
        line-height: 16/14*1em;
    }
}

.flatpickr-days {
    width: 100%;
    overflow: initial;
}

.dayContainer {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 34px);
    justify-content: space-around;
    align-items: center;
    width: auto;
    min-width: auto;
    max-width: 100%;

    .calendar-inline & {
        grid-template-rows: repeat(6, 39px);
    }
}

.flatpickr-day {
    flex-basis: auto;
    width: 100%;
    max-width: 26px;
    height: 26px;
    margin: auto!important;
    line-height: 26px;
    font-weight: 500;
    color: inherit;
    border: none;
    filter: drop-shadow(0px 8px 16px rgba(0, 129, 255, 0.2));
    user-select: none;

    .calendar-inline & {
        max-width: 32px;
        height: 32px;
        line-height: 32px;
    }

    [data-theme="dark"] & {
        filter: drop-shadow(0px 8px 16px rgba(0, 55, 250, 0.3));
    }

    .event {
        position: absolute;
        bottom: 2px;
        left: calc(50% - 2px);
        width: 4px;
        height: 4px;
        background-color: var(--red-color);
        border-radius: 50%;

        &--upcoming {
            background-color: var(--green-color);
        }
    }

    &.today,
    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.selected.inRange,
    &.selected.startRange,
    &.selected.endRange,
    &.startRange,
    &.endRange,
    &.startRange.prevMonthDay,
    &.endRange.prevMonthDay,
    &.startRange.nextMonthDay,
    &.endRange.nextMonthDay,
    &.endRange.nextMonthDay.selected {
        background-color: var(--primary-color);
        color: var(--white-color);

        &.prevMonthDay,
        &:hover,
        &:focus {
            background-color: var(--primary-color);
            color: var(--white-color);
        }
    }

    &.inRange:not(.startRange),
    &.endRange {
        box-shadow: -12px 0 var(--primary-color)!important;

        &:nth-child(1),
        &:nth-child(8),
        &:nth-child(15),
        &:nth-child(22),
        &:nth-child(29),
        &:nth-child(36),
        &:nth-child(43) {
            box-shadow: none!important;
        }
    }

    &.selected,
    &.selected:hover,
    &.selected.prevMonthDay,
    &.selected.nextMonthDay {
        background-color: var(--control-background-hover);
        color: var(--text-primary-color);
    }


    &.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus {
        background-color: var(--control-background-hover);
    }

    &.flatpickr-disabled,
    &.flatpickr-disabled:hover,
    &.prevMonthDay,
    &.nextMonthDay,
    &.notAllowed,
    &.notAllowed.prevMonthDay,
    &.notAllowed.nextMonthDay {
        color: var(--text-secondary-color);
    }
}

.calendar-inline {
    width: 100%;
    margin: 0 auto 26px;

    .cur-year {
        & {
            display: none!important;
        }
    }
}

.calendar-widget {
    width: 100%;
    margin: auto;

    &__row {
        display: flex;
        justify-content: space-between;
        margin-left: -1rem;
    }

    &__item {
        display: flex;
        flex-flow: column;
        padding-left: 1rem;

        &--left {
            text-align: center;
        }
    }

    &__day {
        flex-grow: 1;
        font-weight: 500;
        font-size: 2rem;
        line-height: 35/30*1em;
        text-transform: uppercase;
    }

    &__weekday {
        vertical-align: top;
        position: absolute;
        top: auto;
        margin-top: -0.7em;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
    }

    &__status {
        flex-grow: 1;
        white-space: nowrap;

        .circle {
            display: inline-block;
            flex-shrink: 0;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            border-radius: 50%;
        }
    }
}