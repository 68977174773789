/* Typography */

h1,
.h1 {
    margin-top: 0;
    font-weight: 700;
}

h2,
.h2 {
    margin-top: 0;
    font-weight: 700;
    font-size: 2rem;

    @include media('<sm-tablet') {
        font-size: 1.6rem;
    }
}

h3,
.h3 {
    margin-top: 0;
    margin-bottom: 15/20*1em;
    font-weight: 500;
    font-size: 20px;
    line-height: 23/20*1em;
}

h4,
.h4 {
    margin-top: 0;
    font-weight: 500;
}

h5,
.h5 {
    margin-top: 0;
    margin-bottom: 10/15 * 1em;
    font-weight: 500;
    font-size: 1rem;
    line-height: 18/15*1em;
}

p {
    margin-bottom: 1.25rem;
}

.text-large {
    font-size: 1.3333rem;
    line-height: 23/20*1em;
}

a {
    color: inherit;
    transition: var(--t-base);

    &:hover {
        color: var(--blue-color);
        text-decoration: none;
    }

    &.text-blue {
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom: 1px solid;
        }
    }

    &.link-under {
        border-bottom: 1px solid;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }
}

.text-sm {
    font-size: 13px;
}

.font-weight-medium {
    font-weight: 500;
}

.text-overflow {
    @include text-ellipsis;
}

.text-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}

.text-clamp--2 {
    -webkit-line-clamp: 2;
}

.text-linethrough {
    text-decoration: line-through;
}

@each $cls, $color in $theme-colors {
    .color-#{$cls} {
        background-color: var($color);
    }
}

@each $cls, $color in $theme-colors {
    .text-#{$cls} {
        color: var($color);
    }
}

.text-light-theme {
    [data-theme='light'] & {
        color: var(--text-secondary-color);
    }
}

.text-dark-theme {
    [data-theme='dark'] & {
        color: var(--text-secondary-color);
    }
}

.text-light-dark-theme {
    [data-theme='dark'] & {
        color: var(--text-primary-color);
    }
}

.page-header {
    margin-bottom: 1rem;

    &--grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, auto));
        align-items: center;
        justify-content: space-between;
    }

    &--inline {
        margin-bottom: 0;
    }

    &__title {
        margin-bottom: 0;
        font-size: 2rem;
        line-height: 35/30*1em;

        .page-header--inline & {
            font-size: 1.3333rem;
            line-height: 23/20*1em;
        }
    }

    &__subtitle {
        margin-top: 0;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 1.3333rem;
        line-height: 23/20*1em;

        @include media('<md') {
            font-size: 15px;
        }
    }
}