/* Badges */
.badge-signal {
    flex-shrink: 0;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid var(--background-secondary-color);
    background-color: var(--red-color);
    pointer-events: none;

    &--green {
        background-color: var(--green-color);
    }
}

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    padding: 0 0.25rem;
    border-radius: 10px;
    font-weight: 400;
    font-size: 10px;
    line-height: 17/10*1em;
    letter-spacing: 0.2px;

    &--sm {
        min-width: 30px;
        font-size: 12px;
        line-height: 18/12*1em;
    }

    &--small {
        min-width: 18px;
        min-height: 18px;
        padding: 0 4px;
        font-size: 10px;
    }

    @each $color, $val in $theme-colors {
        &--#{$color} {
            color: var(--white-color);
            background-color: var(#{$val});
        }
    }
}

.badge-help {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    margin-top: -4px;
    cursor: pointer;
    color: var(--text-secondary-color);

    svg[class*="icon"] {
        height: 16px;
        width: 100%;
    }
}