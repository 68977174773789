/* Labels */

.label {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    font-size: 13px;
    line-height: 15/13*1em;
    border-radius: var(--radius-base);

    &__icon {
        width: 22px;
        height: 22px;
        margin-right: 8px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &--md {
        padding: 7px 1.6rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 18/15*1em;
    }

    &--lg {
        padding: 9px 1rem;
        border-radius: 3.3333rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 18/15*1em;
    }

    &--primary {
        color: var(--white-color);
        background-color: var(--primary-color);
    }
}