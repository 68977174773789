/* Lightbox for Gallery */
.pswp {
    &__bg {
        background-color: var(--black-color-3);
    }

    &__button {
        svg {
            fill: var(--white-color);
        }

        &--arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            margin: -25px 20px 0;
            border-radius: 50%;
            background-color: var(--black-color)!important;
            transition: opacity 0.2s;

            @include media('<sm-tablet') {
                display: none;
            }

            &:focus,
            &:hover {
                opacity: 1;
            }

            &:active {
                outline: none;
                opacity: 0.9;
            }

            svg {
                width: 22px;
                height: 22px;
                fill: var(--white-color);
            }
        }

        &--arrow--prev {
            svg {
                transform: rotate(-90deg);
            }
        }

        &--arrow--next {
            svg {
                transform: rotate(90deg);
            }
        }
    }

    &__counter {
        position: absolute;
        top: 18px;
        right: 0;
        left: 0;
        width: max-content;
        margin: auto;
    }

    &__caption {
        position: absolute;
        right: 15px;
        bottom: 24px;
        left: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-width: 740px;
        margin: auto;
        text-align: center;
        color: var(--white-color);
        overflow: hidden;
    }
}