/* File Manager */

.file-manager {
    --files-top-height: 4.2rem;
    display: grid;
    grid-template-columns: 240px 1fr;

    @include media('<lg') {
        grid-template-columns: 0 1fr;
        padding-top: 2rem;
    }

    @include media('<sm-tablet') {
        padding-top: 1.5rem;
    }

    .sidebar-panel {
        position: fixed;
        top: var(--header-height);

        @include media('<lg') {
            @include sidebarPanelCollapse(fixed, calc(var(--header-height) + var(--subheader-height)));
        }

        &__top {
            min-height: var(--files-top-height);
        }
    }

    .page-header {
        @include media('<lg') {
            display: none;
        }
    }

    &__content {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    &__section {
        &:not(:last-child) {
            margin-bottom: 5rem;

            @include media('<sm-tablet') {
                margin-bottom: 2rem;
            }
        }
    }

    &__section-title {
        margin-bottom: 1.3333rem;
    }

    &__section-cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
        grid-gap: 1.6rem;
    }

    &__table {
        .colgroup-1 {
            width: 70px;
        }

        .colgroup-2 {
            width: 400px;
        }
    }
}

.files-card {
    --gutter: 16px;
    position: relative;
    display: flex;
    min-width: 190px;
    padding: 17px var(--gutter) 12px;
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-shadow);

    &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--background-secondary-color);
        border-radius: var(--card-border-radius);
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 76%;
        height: 50px;
        margin: auto;
        box-shadow: var(--dropdown-shadow-shape);
    }

    &__content {
        position: relative;
        z-index: 2;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__checkbox-wrapper {
        position: absolute;
        inset: 0 auto auto 0;

        .checkbox {
            display: block;
            margin: 0;
        }
    }

    &__more {
        position: absolute;
        inset: -8px -13px auto auto;

        .items-more__button {
            width: 32px;
            height: 32px;
        }
    }

    &__icon {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        margin: 11px auto 0;
        color: var(--orange-color-2);

        &--file {
            svg {
                width: 3.6667rem;
                height: 4.6667rem;
            }
        }
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
        margin-top: 7px;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        overflow: hidden;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 1.6rem;
        font-size: 13px;
        line-height: 15/13*1em;
        color: var(--text-secondary-color);
    }
}