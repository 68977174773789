/* Contacts */

.contacts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 360px));
    grid-gap: 100px 2rem;
    padding: 6rem 0 1.6rem;
    justify-content: center;

    @include media('>sm-tablet') {
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }

    @include media('>md') {
        grid-template-columns: repeat(auto-fit, minmax(290px, 360px));
    }
}

.contact-card {
    padding-top: 0;

    .card__tools-more {
        position: absolute;
        top: 1.0667rem;
        right: 1.0667rem;

        .dropdown-items {
            right: 0;
            transform: none;
        }
    }

    .card__body,
    .card__footer .card__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card__body {
        flex-grow: 0;
        padding-bottom: 1.6rem;
    }

    .card__footer {
        flex-grow: 1;
    }

    &__avatar {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: flex-start;
        width: 200px;
        height: 170px;
        margin-top: -4.6667rem;
        margin-bottom: 1.6rem;

        svg {
            width: 100%;
            height: 100%;
            min-height: 210px;
            margin-top: -4px;
            pointer-events: none;
        }
    }

    &__title {
        margin-bottom: 10/30*1em;
        font-weight: 500;
        font-size: 2rem;
        line-height: 35/30*1em;
        text-align: center;
    }

    &__address {
        display: grid;
        grid-gap: 16px;
        list-style: none;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    &__address-item {
        display: grid;
        grid-template: auto / 100px auto;
        justify-content: space-between;
        align-items: start;
        grid-gap: 8px;
    }

    &__address-left {
        display: grid;
        align-items: center;
        grid-gap: 8px;
        grid-template-columns: 22px 1fr;

        svg[class*="icon"] {
            width: 22px;
            height: 22px;
            fill: var(--text-secondary-color);
        }
    }

    &__address-right {
        text-align: right;
    }
}