/* Fonts */
@font-face {
    font-family: 'Comfortaa';
    src: url('../fonts/Comfortaa-Bold.woff2') format('woff2'),
        url('../fonts/Comfortaa-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rounded Mplus 1c';
    src: url('../fonts/RoundedMplus1c-Black.woff2') format('woff2'),
        url('../fonts/RoundedMplus1c-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Material Icons';
    src: url('../fonts/MaterialIcons/MaterialIcons-Regular.eot');
    src: url('../fonts/MaterialIcons/MaterialIcons-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MaterialIcons/MaterialIcons-Regular.woff2') format('woff2'),
    url('../fonts/MaterialIcons/MaterialIcons-Regular.woff') format('woff'),
    url('../fonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype'),
    url('../fonts/MaterialIcons/MaterialIcons-Regular.svg#MaterialIcons-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
